import React from "react";
import { Router } from "@reach/router";
import Profile from "../components/Profile";
import Details from "../components/Details";
import Login from "../components/Login";
import PrivateRoute from "../components/PrivateRoute";
import DashboardPage from "./dashboard";

const App = () => {
  return (
    <>
      <Router>
        <PrivateRoute path="/app/details" component={Details} />
        <PrivateRoute path="/app/profile" component={Profile} />
        <PrivateRoute path="/app/dashboard" component={DashboardPage} />
        <Login path="/app/login" />
      </Router>
    </>
  );
};
export default App;
