import React from "react";
import { navigate } from "gatsby";
import Form from "./Form";
import View from "./View";
import {
  handleLogin,
  isLoggedIn,
  logout,
  saveUser,
  CheckLogin,
} from "../utils/auth";
import Layout from "./Layout";

class Login extends React.Component {
  state = {
    username: ``,
    password: ``,
  };

  handleUpdate(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    let usernameinputvalue = document.getElementById("MyEmail").value;
    let passwordinputvalue = document.getElementById("MyPassword").value;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append(
      "Cookie",
      ".AspNet.Cookies=F8WF1tse1m4jPeVmq_y8v1PigUs5Dd7FfbcqJis39hCNEd1j5bfcb5Of5jeqAps4iebShIkJsGxTzQyGDEnIEqIUmZdG5PJzsXSHx2OS8UvrB-N8u1DhQeMksnPhA2IiV9j2GcDR-BIfGoJCvztFJRfnwSNhDftqKTUDcA68GTeji3c1fPA0VCIO7GdUeJ20Cu-VsZ5YqlTd7kjn15jHBF5xKy2_xuxvSy-UXNE_H9PEasutlUqZBrqVSOgRiAFC3aw_bZ9A1f-ER3L3apeVplyTs2gA_RbKd7JFhiKDiUvLHaBhWKbAu56hYy1_4CDnZBXqvGi5rdFe3siOTawWwlGj-qNhCStSuCDk2SL-DXmgktBbDYgpEF9KU_9Qv2zcahH5ncA1WUiFbkykRsowRvIXsOOxUIrtEcrEhi7DSv1ThsRP_MmGkK92wgjXcXwtAsUJOvK9VnrdWnGMOayErv02949RlnYs5oqHNiV_YVi7j9eC0jFAje45YrEoFrBgQJaHG0RIndTeD6GHaK3vQg"
    );

    var urlencoded = new URLSearchParams();
    urlencoded.append("password", passwordinputvalue);
    urlencoded.append("username", usernameinputvalue);
    urlencoded.append("grant_type", "password");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };
    fetch(`${window.$apiurl}/token`, requestOptions)
      .then((response) => {
        /* if (response.status === 200) {
          navigate("/app/dashboard");
        } */
        return response?.text() ?? "";
      })

      .then(async (result) => {
        var json = JSON.parse(result);
        try {
          if (
            json?.error_description ===
            "The user name or password is incorrect."
          ) {
            document.querySelector(".addressheaderror").style.display = "block";
          } else {
            document.querySelector(".addressheaderror").style.display = "none";
          }
          if (json.access_token) {
            saveUser({
              name: json?.userName ?? "",
              email: json?.userName ?? "",
              token: json.access_token ?? "",
            });
            await navigate(`/app/dashboard`);
            localStorage.setItem("loginaccesstokec", json.access_token);
            localStorage.setItem("loginemail", json.userName);
            document.getElementById("Name").innerHTML =
              localStorage.getItem("loginemail");
          }
        } catch (error) {}
      })
      .catch((error) => console.log("error", error));
    e.preventDefault();
  };

  render() {
    if (isLoggedIn()) {
      navigate(`/app/dashboard`);
    }
    return (
      <Layout>
        <View title="Log In">
          <p
            className="addressheaderror"
            style={{
              display: "none",
              fontSize: "23px",
              color: "red",
            }}
          >
            The user name or password is incorrect.
          </p>
          <Form
            handleUpdate={(e) => this.handleUpdate(e)}
            handleSubmit={(e) => this.handleSubmit(e)}
          />
        </View>
      </Layout>
    );
  }
}

export default Login;
